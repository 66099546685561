import React, { useEffect, useState } from "react";
import "./App.css";
import Menu from "./Compoments/Menu";
import Film from "./Compoments/Film_pas";
import Footer from "./Compoments/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Obsah from "./Compoments/Obsah";
import Aktuality from "./Compoments/Aktuality_z_kraju";
import Oprojektu from "./Compoments/Oprojektu";
import Reference from "./Compoments/Reference";
import Hledatepomoc from "./Compoments/Hledatepomoc";
import Kontakt from "./Compoments/Kontakt";
import InformaceProjektu from "./Compoments/Informace_o_projektu";
import PartneriProjektu from "./Compoments/Partneri_projektu";
import KontaktyKraj from "./Compoments/Kontakty_na_kraj";
import Dokumenty from "./Compoments/Dokumenty";
import DokumentyDetail from "./Compoments/Dokumenty_detail";
import Aktualne from "./Compoments/Aktualne";
import AktualneDetail from "./Compoments/Aktualne_detail";
import Seminare from "./Compoments/Seminare";
import SeminareDetail from "./Compoments/Seminare_detail";
import AktualityKrajeDetail from "./Compoments/Aktuality_z_kraju_detail";
import ReferenceDetail from "./Compoments/Reference_detail";
import Newsletter from "./Compoments/Newsletter";
import ArrowUp from "./Compoments/Components_2/Arrow_up";
import Skoleni from "./Compoments/Skoleni";
import PracovniListy from "./Compoments/Pracovni_listy";
import VimKamKlikam from "./Compoments/Vim_kam_klikam";
import VimKamKlikamClanky from "./Compoments/Vim_kam_klikam_clanky";
import VimKamKlikamClankyDetail from "./Compoments/Vim_kam_klikam_clanky_detail";
import GDPR from "./Compoments/GDPR";
import GA from "./Compoments/ga";

function App() {
    const [showNewsletter, setShowNewsletter] = useState(false);
    const [showArrowUp, setShowArrowUp] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowNewsletter(true);
        }, 10000);

        return () => clearTimeout(timeout);
    }, []);

    const handleNewsletterClose = () => {
        // Funkce pro uzavření newsletteru
        setShowNewsletter(false);
    };

    const toggleNewsletter = () => {
        setShowNewsletter(!showNewsletter);

        if (showNewsletter) {
            setShowArrowUp(false);
        }
    };

    useEffect(() => {
        setShowArrowUp(!showNewsletter);
    }, [showNewsletter]);

    return (
        <Router>
            <GA />
            <div>
                <div className="film-container">
                    <Routes>
                        <Route path="/" element={<Film />} />
                    </Routes>
                </div>
                <div className="menu">
                    <Menu />
                    <Routes>
                        <Route path="/" element={<Obsah />} />
                        <Route path="/aktuality-z-kraju" element={<Aktuality />} />
                        <Route path="/aktuality-z-kraju-detail/:id" element={<AktualityKrajeDetail />} />
                        <Route path="/oprojektu" element={<Oprojektu />} />
                        <Route path="/reference" element={<Reference />} />
                        <Route path="/reference-detail/:id" element={<ReferenceDetail />} />
                        <Route path="/hledatepomoc" element={<Hledatepomoc />} />
                        <Route path="/kontakt" element={<Kontakt />} />
                        <Route path="/informace-o-projektu" element={<InformaceProjektu />} />
                        <Route path="/partneri-projektu" element={<PartneriProjektu />} />
                        <Route path="/kontakty-na-kraje" element={<KontaktyKraj />} />
                        <Route path="/dokumenty" element={<Dokumenty />} />
                        <Route path="/dokument-detail/:id" element={<DokumentyDetail />} />
                        <Route path="/aktualne" element={<Aktualne />} />
                        <Route path="/aktualne-detail/:id" element={<AktualneDetail />} />
                        <Route path="/seminare-konference" element={<Seminare />} />
                        <Route path="/seminare-konference-detail/:id" element={<SeminareDetail />} />
                        <Route path="/skoleni" element={<Skoleni />} />
                        <Route path="/pracovni-listy" element={<PracovniListy />} />
                        <Route path="/vim-kam-klikam" element={<VimKamKlikam />} />
                        <Route path="/vim-kam-klikam-clanky/:id" element={<VimKamKlikamClanky />} />
                        <Route
                            path="/vim-kam-klikam-clanky-detail/:id/:categoryId"
                            element={<VimKamKlikamClankyDetail />}
                        />
                        <Route path="/gdpr" element={<GDPR />} />
                    </Routes>
                </div>
                <div>
                    <div className={showNewsletter ? "newsletter-container" : ""}>
                        {showNewsletter && <Newsletter onClose={handleNewsletterClose} />}
                    </div>
                    <ArrowUp onClick={toggleNewsletter} showArrow={showArrowUp} />
                    <div className="footer">
                        <Footer />
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;
