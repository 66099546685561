import React, { useEffect, useRef, useCallback } from "react";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const GA = () => {
    const { t } = useTranslation("global");
    const hasInitialized = useRef(false);

    const initializeGA = useCallback(() => {
        if (!hasInitialized.current) {
            ReactGA.initialize("G-GEEJR8B5FN");
            ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
            hasInitialized.current = true;
        }
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined") {
            const consent = localStorage.getItem("cookieConsent");
            if (consent === "true" && !hasInitialized.current) {
                initializeGA();
            }
        }
    }, [initializeGA]);

    const handleCookiesAccept = () => {
        if (!localStorage.getItem("cookieConsent")) {
            localStorage.setItem("cookieConsent", "true");
            initializeGA();
        }
    };

    return (
        <CookieConsent
            location="bottom"
            buttonText={t("accept")}
            cookieName="userConsent"
            style={{ background: "#2B373B", color: "#fff" }}
            onAccept={handleCookiesAccept}
            buttonStyle={{
                background: "#4CAF50",
                color: "#fff",
                fontSize: "13px",
                borderRadius: "3px",
                padding: "10px 20px",
            }}
            expires={150}
        >
            {t("cookies_consent")}
        </CookieConsent>
    );
};

export default GA;

